/* Custom styles for react-big-calendar */
.rbc-calendar {
  background-color: #1f2937;
  border-radius: 0.75rem;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: 1px solid #374151;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  color: #f3f4f6;
}

.rbc-header {
  padding: 10px 3px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  font-size: 0.75rem !important;
  min-height: 40px !important;
  border-bottom: 1px solid #374151;
}

.rbc-event {
  padding: 0 !important;
  font-size: 0.75rem !important;
  min-height: 20px !important;
  max-height: 22px !important;
  overflow: hidden !important;
  border: none !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  transition: transform 0.2s ease, box-shadow 0.2s ease !important;
  opacity: 0.95 !important;
  margin: 1px !important;
}

.rbc-event:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.rbc-event.rbc-selected {
  background: linear-gradient(135deg, #6D28D9, #5B21B6) !important;
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
}

.rbc-event-content {
  padding: 1px 2px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.rbc-today {
  background-color: rgba(59, 130, 246, 0.1);
}

.rbc-toolbar {
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  background-color: #1f2937;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.rbc-toolbar button {
  color: #d1d5db;
  background-color: #374151;
  border: 1px solid #4b5563;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.rbc-toolbar button:hover {
  background-color: #4b5563;
  color: #f9fafb;
}

.rbc-toolbar button.rbc-active {
  background-color: #3b82f6;
  color: white;
  border-color: #2563eb;
}

.rbc-toolbar-label {
  font-size: 1.25rem;
  font-weight: 600;
  color: #f9fafb;
}

.rbc-time-slot {
  color: #9ca3af;
}

.rbc-timeslot-group {
  min-height: 40px !important;
  border-bottom: 1px solid #4b5563;
}

.rbc-time-view {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #374151;
}

.rbc-time-header {
  background-color: #1f2937;
  border-bottom: 1px solid #374151;
}

.rbc-time-content {
  border-top: 1px solid #374151;
}

.rbc-current-time-indicator {
  background-color: #ef4444;
  height: 2px;
}

.calendar-event {
  margin: 2px !important;
  transition: all 0.2s ease;
  color: white !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2) !important;
  border-radius: 0.5rem !important;
  background: linear-gradient(135deg, #8B5CF6, #7C3AED) !important;
  border: 2px solid transparent !important;
}

.calendar-event:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.3) !important;
  opacity: 1 !important;
  border-color: #A78BFA !important;
}

.calendar-event.selected-event {
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
  background: linear-gradient(135deg, #6D28D9, #5B21B6) !important;
}

.rbc-event {
  border: none !important;
  opacity: 0.95 !important;
}

.rbc-event-content {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: white !important;
  height: 100% !important;
  padding: 4px 8px !important;
}

.rbc-day-slot .rbc-event {
  border: none;
  padding: 0;
}

.rbc-row-segment {
  padding: 1px 2px !important;
}

.rbc-off-range-bg {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.rbc-off-range {
  color: #6b7280;
}

.rbc-date-cell {
  color: #e5e7eb;
  padding: 4px;
}

.rbc-month-view {
  border-radius: 0.5rem;
  overflow: hidden;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  min-height: calc(100vh - 160px) !important;
}

/* Improve the week and day views */
.rbc-time-view {
  background-color: #111827; /* Darker background for better contrast */
}

/* Reduce the number of dividing lines in time slots */
.rbc-time-slot {
  border-top: none !important; /* Remove default borders between time slots */
}

/* Only show borders for every hour instead of every 30 minutes */
.rbc-timeslot-group {
  border-bottom: 1px solid rgba(75, 85, 99, 0.3) !important; /* Lighter borders between hours */
}

/* Better styling for the time gutter */
.rbc-time-gutter .rbc-time-slot {
  color: #9ca3af !important;
  font-size: 0.75rem !important;
}

/* Adjust the time column */
.rbc-time-gutter {
  background-color: #1f2937 !important;
  border-right: 1px solid #374151 !important;
  min-width: 70px !important;
  text-align: center !important;
}

/* Enhance the day header */
.rbc-time-header-content {
  border-left: 1px solid rgba(75, 85, 99, 0.3) !important;
  font-weight: 600 !important;
}

/* Improve the "all day" section */
.rbc-allday-cell {
  background-color: #1f2937 !important;
  border-bottom: 1px solid #374151 !important;
  max-height: 60px !important;
}

/* Enhance events in the time view */
.rbc-time-view .rbc-event {
  background-color: #3b82f6 !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3) !important;
  min-height: 24px !important;
  max-height: none !important; /* Allow events to grow in height */
  padding: 2px 4px !important;
  margin: 0 2px !important;
}

/* Event content in time view */
.rbc-time-view .rbc-event-content {
  font-size: 0.8rem !important;
  line-height: 1.2 !important;
  padding: 2px 4px !important;
}

/* Improve the event label */
.rbc-event-label {
  font-size: 0.7rem !important;
  padding: 0 4px !important;
  opacity: 0.8 !important;
}

/* Add hover effect for events in time view */
.rbc-time-view .rbc-event:hover {
  transform: scale(1.02) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4) !important;
  z-index: 10 !important;
}

/* Current time indicator */
.rbc-current-time-indicator {
  background-color: #ef4444 !important;
  height: 2px !important; 
  z-index: 5 !important;
  opacity: 0.8 !important;
}

/* Custom calendar container */
.custom-calendar {
  background-color: #111827 !important;
  border-radius: 0.75rem !important;
  overflow: hidden !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

/* Handle resizable event styles */
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  width: 8px !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-radius: 2px !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

/* Style for the resize handles */
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  height: 8px !important;
  width: 100% !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-radius: 2px !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

/* Week view - make the columns more clear */
.rbc-time-view .rbc-day-slot {
  border-left: 1px solid rgba(75, 85, 99, 0.2) !important;
}

/* Highlight the current day column */
.rbc-time-view .rbc-day-slot.rbc-today {
  background-color: rgba(59, 130, 246, 0.05) !important;
}

/* Month view improvements */
.rbc-month-view .rbc-month-row {
  border-bottom: 1px solid rgba(75, 85, 99, 0.3) !important;
  flex: 1 1 0 !important;
  min-height: 120px !important;
}

.rbc-month-view .rbc-day-bg {
  border-left: 1px solid rgba(75, 85, 99, 0.2) !important;
}

.rbc-month-view .rbc-day-bg.rbc-today {
  background-color: rgba(59, 130, 246, 0.1) !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Month view event styles */
.rbc-month-view .rbc-event {
  padding: 0 !important;
  font-size: 0.7rem !important;
  min-height: 18px !important;
  max-height: 18px !important;
  overflow: hidden !important;
  margin: 1px 0 !important;
  border-radius: 2px !important;
}

.rbc-month-view .rbc-event-content {
  padding: 0 2px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: 18px !important;
}

/* Week/Day view event styles */
.rbc-time-view .rbc-event {
  padding: 2px 5px !important;
  min-height: 24px !important;
}

/* Improve event appearance */
.rbc-event {
  border-radius: 2px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
}

/* Reduce row height in month view - but not too much, ensure it doesn't look squished */
.rbc-month-row {
  min-height: 120px !important;
  max-height: none !important;
  overflow: visible !important;
  height: auto !important;
  display: flex !important;
  flex: 1 1 0 !important;
}

/* Adjust day cell padding */
.rbc-day-bg {
  padding: 0 !important;
}

.rbc-date-cell {
  padding: 2px 5px !important;
  text-align: right !important;
  font-size: 0.8rem !important;
}

/* Adjust row segment padding */
.rbc-row-segment {
  padding: 0 1px !important;
}

/* Highlight today's cell */
.rbc-day-bg.rbc-today {
  background-color: rgba(66, 153, 225, 0.1) !important;
}

/* Improve header appearance */
.rbc-header {
  padding: 10px 3px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  font-size: 0.75rem !important;
}

/* Improve off-range day appearance */
.rbc-off-range-bg {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/* Improve selected cell appearance */
.rbc-selected-cell {
  background-color: rgba(66, 153, 225, 0.2) !important;
}

/* Custom scrollbar for the calendar */
.rbc-calendar ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.rbc-calendar ::-webkit-scrollbar-track {
  background: #1f2937;
  border-radius: 4px;
}

.rbc-calendar ::-webkit-scrollbar-thumb {
  background: #4b5563;
  border-radius: 4px;
}

.rbc-calendar ::-webkit-scrollbar-thumb:hover {
  background: #6b7280;
}

/* Drag and Drop Styles */
.rbc-addons-dnd .rbc-addons-dnd-dragging .rbc-event {
  opacity: 0.7 !important;
  box-shadow: 0 0 0 2px #3b82f6 !important;
}

.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: rgba(59, 130, 246, 0.1) !important;
}

.rbc-addons-dnd-drag-preview {
  opacity: 0.7 !important;
  border: 2px dashed #3b82f6 !important;
  background-color: rgba(59, 130, 246, 0.2) !important;
}

/* Style for drag-preview */
.rbc-addons-dnd-drag-preview {
  z-index: 1000 !important;
  pointer-events: none !important;
}

/* Style for drag-over cell */
.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: rgba(59, 130, 246, 0.1) !important;
}

/* Route Indicators */
.route-event {
  background: linear-gradient(135deg, #8B5CF6, #7C3AED) !important;
  border: 2px solid transparent !important;
}

.route-event:hover {
  border-color: #A78BFA !important;
}

.route-event.selected-event {
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
  background: linear-gradient(135deg, #6D28D9, #5B21B6) !important;
}

.recurring-event {
  background: linear-gradient(135deg, #9333EA, #7C3AED) !important;
}

.recurring-event:hover {
  border-color: #C4B5FD !important;
}

.recurring-event.selected-event {
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
  background: linear-gradient(135deg, #7E22CE, #6D28D9) !important;
}

/* Route job styling */
.route-job {
  background: linear-gradient(135deg, #8B5CF6, #7C3AED) !important;
}

.route-job:hover {
  border-color: #A78BFA !important;
}

.route-job.selected-event {
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
  background: linear-gradient(135deg, #6D28D9, #5B21B6) !important;
}

/* Job pool styles */
.job-pool-item {
  background: linear-gradient(135deg, #1f2937, #111827);
  border: 1px solid #374151;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s;
}

.job-pool-item:hover {
  transform: translateY(-2px);
  border-color: #3b82f6;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Loading state */
.calendar-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Enhanced Job Pool Styles */
.job-pool-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #1f2937;
}

.job-pool-header {
  padding: 1rem;
  border-bottom: 1px solid #374151;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-pool-search {
  margin: 0.5rem 1rem;
}

.job-pool-filters {
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem 0.5rem;
  border-bottom: 1px solid #374151;
}

.job-pool-list {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

/* Enhanced Calendar Event Styles */
.calendar-event-one-time {
  border-left: 4px solid #3B82F6 !important;
}

.calendar-event-recurring {
  border-left: 4px solid #10B981 !important;
}

/* Context Menu Styles */
.context-menu {
  position: absolute;
  z-index: 1000;
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  min-width: 200px;
  overflow: hidden;
}

.context-menu-header {
  padding: 0.75rem 1rem;
  background-color: #111827;
  border-bottom: 1px solid #374151;
  font-weight: 500;
  color: #f9fafb;
}

.context-menu-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #e5e7eb;
  display: flex;
  align-items: center;
}

.context-menu-item:hover {
  background-color: #374151;
}

.context-menu-item-icon {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.context-menu-divider {
  height: 1px;
  background-color: #374151;
  margin: 0.25rem 0;
}

/* Modal styling */
.modern-modal {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1050 !important;
}

.modal-backdrop-dark {
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 1040 !important;
}

.modal-content {
  background-color: #1f2937 !important;
  color: white !important;
  border: 1px solid #374151 !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.modal-header {
  border-bottom: 1px solid #374151 !important;
  padding: 1rem 1.5rem !important;
}

.modal-body {
  padding: 1.5rem !important;
}

.modal-footer {
  border-top: 1px solid #374151 !important;
  padding: 1rem 1.5rem !important;
}

/* Override Bootstrap modal styles */
.modal {
  position: fixed !important;
  z-index: 1050 !important;
}

.modal-dialog {
  margin: 1.75rem auto !important;
}

.modal-open {
  overflow: hidden !important;
}

/* Custom Tabs */
.custom-tabs .ant-tabs-nav {
  margin-bottom: 1.5rem;
}

.custom-tabs .ant-tabs-tab {
  color: #9ca3af;
}

.custom-tabs .ant-tabs-tab:hover {
  color: #f9fafb;
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3b82f6;
}

.custom-tabs .ant-tabs-ink-bar {
  background-color: #3b82f6;
}

/* Assign Job Modal Styles */
.assign-job-modal .ant-modal-content {
  background-color: #1f2937;
  border: 1px solid #374151;
}

.assign-job-modal .ant-modal-header {
  background-color: #111827;
  border-bottom: 1px solid #374151;
}

.assign-job-modal .ant-modal-title {
  color: #f9fafb;
}

.assign-job-modal .ant-modal-close {
  color: #9ca3af;
}

.assign-job-modal .ant-modal-close:hover {
  color: #f9fafb;
}

.assign-job-modal .ant-modal-body {
  padding: 0;
}

.assign-job-modal .ant-select-selector {
  background-color: #374151 !important;
  border-color: #4b5563 !important;
  color: #f9fafb !important;
}

.assign-job-modal .ant-select-selection-placeholder {
  color: #9ca3af !important;
}

.assign-job-modal .ant-select-arrow {
  color: #9ca3af !important;
}

/* Ant Design Overrides */
.ant-select-dropdown {
  background-color: #1f2937 !important;
  border: 1px solid #374151 !important;
}

.ant-select-item {
  color: #e5e7eb !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #374151 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #3b82f6 !important;
  color: #ffffff !important;
}

.ant-badge-count {
  box-shadow: none !important;
}

.ant-empty-description {
  color: #9ca3af !important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .rbc-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .rbc-toolbar-label {
    margin: 0.5rem 0;
  }
  
  .rbc-btn-group {
    margin-bottom: 0.5rem;
  }
}

/* Month week rows container */
.rbc-month-view .rbc-month-header {
  flex: 0 0 auto !important;
  min-height: 40px !important;
}

/* Month container for all weeks */
.rbc-month-view .rbc-month-rows-container {
  flex: 1 1 auto !important;
  display: flex !important;
  flex-direction: column !important;
  min-height: calc(100% - 40px) !important;
}

/* Ensure rows take up full height */
.rbc-month-view .rbc-rows {
  flex: 1 1 auto !important;
  display: flex !important;
  flex-direction: column !important;
  min-height: 100% !important;
}

/* Calendar container */
.calendar-container {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

/* For .rbc-calendar inside .custom-calendar */
.custom-calendar .rbc-calendar {
  flex: 1 1 auto !important;
  min-height: 100% !important;
}