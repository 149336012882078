/* RouteMap.css - Custom styles for the Route Map component */

/* Make sure tab text color is visible */
.route-map-tabs .ant-tabs-tab {
  color: #bfdbfe !important; /* Light blue color */
}

.route-map-tabs .ant-tabs-tab:hover {
  color: #60a5fa !important; /* Brighter blue on hover */
}

.route-map-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3b82f6 !important; /* Medium blue when active */
  font-weight: 600;
}

/* Ensure text in cards and other elements is visible */
.text-white {
  color: #fff !important;
}

.text-gray-300 {
  color: #d1d5db !important;
}

.text-gray-400 {
  color: #9ca3af !important;
}

/* Fix the background color of tab container */
.ant-tabs-nav {
  background-color: rgba(17, 24, 39, 0.7) !important;
  border-radius: 0.375rem;
  margin-bottom: 1rem !important;
}

/* Make markers more visible */
.gm-style .gm-style-iw-c {
  background-color: #f9fafb !important;
  padding: 12px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

/* Ensure legend is visible */
.map-legend {
  background-color: rgba(31, 41, 55, 0.85) !important;
  color: #fff !important;
  border: 1px solid #4b5563 !important;
}

/* Enhance route display */
/* Note: These will apply through Google Maps' dynamic styling */
.gm-style > div > div > div > div > div > div > div {
  /* Target route lines */
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.3));
}

/* Optimize for route visibility */
.google-map-container {
  --route-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Make optimized route more visible */
.optimized-route-path {
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.6));
} 