/* Chat Bubble Container */
.chat-bubble-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  font-family: 'Inter', sans-serif;
}

/* Chat Bubble Button */
.chat-bubble-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #3b82f6;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-size: 24px;
  transition: all 0.3s ease;
}

.chat-bubble-button:hover {
  background-color: #2563eb;
  transform: scale(1.05);
}

/* Chat Window */
.chat-window {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 350px;
  height: 500px;
  background-color: #1e293b;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  border: 1px solid #334155;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Chat Header */
.chat-header {
  background-color: #3b82f6;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2563eb;
}

.chat-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Chat Messages */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #0f172a;
}

.welcome-message {
  text-align: center;
  color: #94a3b8;
  margin: auto 0;
  padding: 20px;
}

.welcome-message p {
  margin: 5px 0;
}

.message {
  max-width: 80%;
  padding: 10px 15px;
  border-radius: 18px;
  position: relative;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.user-message {
  align-self: flex-end;
  background-color: #3b82f6;
  color: white;
  border-bottom-right-radius: 4px;
}

.bot-message {
  align-self: flex-start;
  background-color: #334155;
  color: #e2e8f0;
  border-bottom-left-radius: 4px;
}

.message-content {
  margin-bottom: 5px;
}

.message-timestamp {
  font-size: 10px;
  opacity: 0.7;
  text-align: right;
}

/* Typing Indicator */
.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background-color: #94a3b8;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.5s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-5px);
  }
}

/* Chat Input Form */
.chat-input-form {
  display: flex;
  padding: 10px;
  background-color: #1e293b;
  border-top: 1px solid #334155;
}

.chat-input-form input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid #334155;
  border-radius: 20px;
  background-color: #0f172a;
  color: white;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
}

.chat-input-form input:focus {
  border-color: #3b82f6;
}

.chat-input-form input::placeholder {
  color: #64748b;
}

.chat-input-form button {
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.chat-input-form button:hover:not(:disabled) {
  background-color: #2563eb;
}

.chat-input-form button:disabled {
  background-color: #64748b;
  cursor: not-allowed;
}

/* Scrollbar Styling */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #0f172a;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #334155;
  border-radius: 6px;
}

/* Responsive Design */
@media (max-width: 480px) {
  .chat-window {
    width: 300px;
    height: 450px;
    bottom: 70px;
  }
  
  .chat-bubble-button {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
} 